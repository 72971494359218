import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import {
  RESULTADO_VIDEOLLAMADA_SOPORTE_1,
  RESULTADO_VIDEOLLAMADA_SOPORTE_2,
  RESULTADO_VIDEOLLAMADA_SOPORTE_OBLIGATORIO,
  RESULTADO_VIDEOLLAMADA_EXTRA,
  EXPLICAR_RESULTADO_VIDEOLLAMADA_EXTRA,
} from 'constants/commonConstants';

// componentes
import Button from 'components/Button';
import { RadioButtonField } from 'components/RadioButton';
import TextAreaField from 'components/HookForm/TextAreaField';

import ModalBox from 'components/Modal';
import styles from 'containers/Videollamada/MotivoRechazo/motivoRechazo.module.css';

const RechazarVideollamadaSoporte = ({
  onSubmit,
  cargandoRechazar,
  modalAbierto,
  onClose,
  textoModal,
  textoConfirmar,
  textoCancelar,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const modalChildren = () => (
    <form className={styles.formulario} onSubmit={handleSubmit(onSubmit)}>
      <RadioButtonField
        register={register}
        name="resultado"
        label={RESULTADO_VIDEOLLAMADA_SOPORTE_1}
        value={1}
        required
      />
      <RadioButtonField
        register={register}
        name="resultado"
        label={RESULTADO_VIDEOLLAMADA_SOPORTE_2}
        value={2}
        required
      />
      {errors.resultado && (
        <span className={styles.motivoRechazoObligatorio}>
          {RESULTADO_VIDEOLLAMADA_SOPORTE_OBLIGATORIO}
        </span>
      )}
      <TextAreaField
        register={register}
        name="resultadoExtra"
        label={RESULTADO_VIDEOLLAMADA_EXTRA}
        type="text"
        maxLength={400}
        error={errors.resultadoExtra}
        placeholder={EXPLICAR_RESULTADO_VIDEOLLAMADA_EXTRA}
        className={styles.textArea}
      />
      <div className={styles.contenedorBotones}>
        <Button
          type="primary"
          size="lg"
          title={textoConfirmar}
          isSubmit
          isLoading={cargandoRechazar}
          className={styles.boton}
        />
        <Button
          type="secondary"
          size="lg"
          title={textoCancelar}
          className={styles.boton}
          callback={onClose}
        />
      </div>
    </form>
  );

  return (
    <ModalBox abierto={modalAbierto} onClose={onClose} texto={textoModal}>
      {modalChildren()}
    </ModalBox>
  );
};

RechazarVideollamadaSoporte.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  cargandoRechazar: PropTypes.bool,
  modalAbierto: PropTypes.bool,
  textoModal: PropTypes.string,
  textoCancelar: PropTypes.string,
  textoConfirmar: PropTypes.string,
};

export default RechazarVideollamadaSoporte;
