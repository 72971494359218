import {
  TIPO_TURNO_CONSULTA,
  TIPO_TURNO_VALIDACION,
} from 'constants/commonConstants';
import dayjs from 'dayjs';

const HORAS_POR_DEFECTO = [
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
];

/**
 * Agrega valores por defecto a las agendas existentes con horarios predefinidos y devuelve un nuevo
 * objeto
 * @param {Object} nuevasHoras las horas a mostrar
 * @param {list[string]} horasPorDefecto las horas que deben mostrarse siempre en la tabla
 * @return {list} un array con todas las horas
 */
export const datosAgendaCompletos = (
  nuevasHoras = {},
  horasPorDefecto = HORAS_POR_DEFECTO,
) => {
  const existentes = nuevasHoras || {};
  const todasLasHoras = [
    ...new Set(Object.keys(existentes).concat(horasPorDefecto)),
  ];

  const date = dayjs().format('YYYY-MM-DD');

  todasLasHoras.sort((a, b) =>
    dayjs(`${date} ${a}`, 'HH:mm').isBefore(
      dayjs(`${date} ${b}`, 'HH:mm'),
      'minute',
    )
      ? -1
      : 1,
  );

  return todasLasHoras.map(hora => [hora, existentes[hora] || []]);
};

/**
 * Devuelve las columnas según el tipo de turno
 * @param {string} tipoTurno el tipo de turno
 * @return {list[Object]} la lista de columnas con sus propiedades
 */
export const columnas = tipoTurno => {
  if (tipoTurno === TIPO_TURNO_VALIDACION) {
    return [
      { nombre: 'Hora', grande: false },
      { nombre: 'Documento', grande: false },
      { nombre: 'Nombre y Apellido', grande: true },
      { nombre: 'Teléfono', grande: true },
      { nombre: 'Mail', grande: true },
      { nombre: 'Nivel', grande: false },
      { nombre: 'Estado', grande: true },
      { nombre: 'Agente', grande: true },
      // Es necesario llenar con celdas vacias el espacio restante
      { nombre: '', grande: false, celdaVacia: true },
      { nombre: '', grande: false, celdaVacia: true },
    ];
  }
  if (tipoTurno === TIPO_TURNO_CONSULTA) {
    return [
      { nombre: 'Hora', grande: false },
      { nombre: 'Documento', grande: false },
      { nombre: 'Nombre', grande: true },
      { nombre: 'Teléfono', grande: true },
      { nombre: 'Mail', grande: true },
      { nombre: 'Estado', grande: true },
      { nombre: 'Agente', grande: true },
      { nombre: 'Motivo solicitud', grande: true },
      // Es necesario llenar con celdas vacias el espacio restante
      { nombre: '', grande: false, celdaVacia: true },
      { nombre: '', grande: false, celdaVacia: true },
    ];
  }

  throw new Error(`tipo turno desconocido: ${tipoTurno}`);
};

/**
 * Extra las columnas según el trámite y el tipo de turno
 * @param {string} tipoTurno el tipo de turno
 * @param {Object} tramite el trámite
 * @return {list[Object]} las columnas normalizadas
 */
export const celdasTramiteInfo = (tipoTurno, tramite) => {
  if (tipoTurno === TIPO_TURNO_VALIDACION) {
    return [
      { key: 'numeroDocumento', value: tramite.numero_documento },
      {
        key: 'nombreApellido',
        value: `${tramite.primer_nombre} ${tramite.primer_apellido}`,
      },
      { key: 'telefono', value: `+${tramite.numero_telefono}` },
      { key: 'email', value: tramite.email },
      { key: 'nivel', value: tramite.nivel },
    ];
  }
  if (tipoTurno === TIPO_TURNO_CONSULTA) {
    return [
      {
        key: 'numeroDocumento',
        value: tramite.informacion_contacto.numero_documento,
      },
      {
        key: 'nombreApellido',
        value: tramite.informacion_contacto.nombre_completo,
      },
      { key: 'telefono', value: tramite.informacion_contacto.numero_telefono },
      { key: 'email', value: tramite.informacion_contacto.correo_electronico },
    ];
  }
  throw new Error(`tipoTurno unknown: ${tipoTurno}`);
};
