import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { showHeader } from 'actions/ui';

// api services
import { obtenerAgendaVideollamada } from 'services/apiServices';
import { tipoTurnoFromPath } from 'utils/tipoTurnos';

// constantes
import {
  errorLlamarText,
  TEXTO_VIDEOLLAMADA_NAVEGADOR_INCOMPATIBLE,
  TITULO_VIDEOLLAMADA,
  TITULO_VIDEOLLAMADA_SOPORTE,
  TEXTO_SELECCIONAR_DIA,
  obtenerTextoVideollamadaEnCurso,
  RETOMAR_TEXT,
  TIPO_TURNO_VALIDACION,
} from 'constants/commonConstants';

// selectors
import {
  tramiteEnCursoSelector,
  consultaEnCursoSelector,
} from 'store/selectors';

// componentes
import AgendaVideollamadaFecha from 'components/AgendaVideollamada/AgendaVideollamadaFecha';
import Card from 'components/Card';
import AgendaVideollamadaTabla from 'components/AgendaVideollamada/AgendaVideollamadaTabla';
import AlertNuevo from 'components/AlertNuevo';
import CardSpinner from 'components/CardSpinner';

import { obtenerDatosAgendaPorDiaDeSemana } from './utils';

import styles from './styles.module.css';

const AgendaVideollamadaContainer = () => {
  const [cargandoDatosAgenda, setCargandoDatosAgenda] = useState(false);
  const [datosAgendaSemana, setDatosAgendaSemana] = useState(null);
  const [fecha, setFecha] = useState(dayjs().format('YYYY-MM-DD'));
  const [datosFecha, setDatosFecha] = useState({});
  const [datosDiasSemana, setDatosDiasSemana] = useState({});
  const [errorLlamar, setErrorLlamar] = useState({
    error: false,
    fecha: null,
    hora: null,
  });

  const tipoTurno = tipoTurnoFromPath(window.location.pathname);

  const tramiteEnCurso = useSelector(tramiteEnCursoSelector);
  const consultaEnCurso = useSelector(consultaEnCursoSelector);

  const dispatch = useDispatch();

  const tituloVideollamada = () =>
    tipoTurno === TIPO_TURNO_VALIDACION
      ? TITULO_VIDEOLLAMADA
      : TITULO_VIDEOLLAMADA_SOPORTE;

  const obtenerAgenda = async fechaAgenda => {
    try {
      const fechaLunesDeLaSemana = dayjs(fechaAgenda)
        .startOf('week')
        .add(1, 'day')
        .format('YYYY-MM-DD');
      const fechaViernesDeLaSemana = dayjs(fechaAgenda)
        .endOf('week')
        .subtract(1, 'day')
        .format('YYYY-MM-DD');

      setCargandoDatosAgenda(true);
      const { data: datosAgenda } = await obtenerAgendaVideollamada(
        tipoTurno,
        fechaLunesDeLaSemana,
        fechaViernesDeLaSemana,
      );

      // Se requieren guardar por separado los datos de las agendas de la semana,
      // los datos de las agendas del dia seleccionado y los datos para los textos del selector de días.
      // Esto se hace para evitar rerenders y fetches innecesarios al momento de cambiar de día.

      // Se guarda en un state los datos de toda la semana
      setDatosAgendaSemana(datosAgenda);
      // Se guarda en un state los datos de la fecha seleccionada
      setDatosFecha(datosAgenda[fechaAgenda].turnos);
      // Se guarda en un state los datos correspondientes a los textos de cada día en el selector de días
      setDatosDiasSemana(
        obtenerDatosAgendaPorDiaDeSemana(tipoTurno, datosAgenda),
      );
    } catch {
      setDatosAgendaSemana({});
    } finally {
      setCargandoDatosAgenda(false);
    }
  };

  const debouncedObtenerAgenda = useCallback(
    debounce(fechaAgenda => {
      obtenerAgenda(fechaAgenda);
    }, 500),
    [],
  );

  useEffect(() => {
    dispatch(showHeader());
    debouncedObtenerAgenda(fecha);
  }, []);

  useEffect(() => {
    if (datosAgendaSemana && !(fecha in datosAgendaSemana)) {
      debouncedObtenerAgenda(fecha);
    } else {
      setDatosFecha(datosAgendaSemana?.[fecha].turnos);
    }
  }, [errorLlamar, fecha]);

  if (datosAgendaSemana === null) {
    return (
      <CardSpinner
        spin
        text="Cargando..."
        className="spinner__transparent__videollamada"
      />
    );
  }

  const esFirefox = navigator.userAgent.toLowerCase().includes('firefox');

  const textoAlertVideollamadaPendiente = obtenerTextoVideollamadaEnCurso(
    tipoTurno === TIPO_TURNO_VALIDACION
      ? tramiteEnCurso?.ciudadano.numero_documento
      : consultaEnCurso?.informacion_contacto.numero_documento,
  );

  return (
    <div className={styles.mainContenedor}>
      {window.REACT_APP_FEATURED_FLAG_ALERT_NAVEGADOR_INCOMPATIBLE &&
        esFirefox && (
          <div className={styles.alertContenedor}>
            <AlertNuevo
              error
              texto={TEXTO_VIDEOLLAMADA_NAVEGADOR_INCOMPATIBLE[0]}
              textoNegrita={TEXTO_VIDEOLLAMADA_NAVEGADOR_INCOMPATIBLE[1]}
            />
          </div>
        )}
      <h1 className={styles.tituloPrincipal}>{tituloVideollamada()}</h1>
      <h2 className={styles.textoSeleccionarDia}>{TEXTO_SELECCIONAR_DIA}</h2>
      <AgendaVideollamadaFecha
        fecha={fecha}
        setFecha={setFecha}
        datosDiasSemana={datosDiasSemana}
        setDatosDiasSemana={setDatosDiasSemana}
      />
      {tipoTurno === TIPO_TURNO_VALIDACION
        ? tramiteEnCurso && (
            <div className={styles.alertContenedorInfo}>
              <AlertNuevo
                texto={textoAlertVideollamadaPendiente[0]}
                textoNegrita={textoAlertVideollamadaPendiente[1]}
                urlTexto={RETOMAR_TEXT}
                url={`/videollamada/${tramiteEnCurso?.id}`}
              />
            </div>
          )
        : consultaEnCurso && (
            <div className={styles.alertContenedorInfo}>
              <AlertNuevo
                texto={textoAlertVideollamadaPendiente[0]}
                textoNegrita={textoAlertVideollamadaPendiente[1]}
                urlTexto={RETOMAR_TEXT}
                url={`/videollamada-soporte/${tramiteEnCurso?.id}`} // aca que va?
              />
            </div>
          )}
      {errorLlamar.error && (
        <div className={styles.alertContenedorInfo}>
          <AlertNuevo
            texto={errorLlamarText(errorLlamar.fecha, errorLlamar.hora)}
          />
        </div>
      )}
      <Card className={styles.card}>
        <AgendaVideollamadaTabla
          agenteTramitePendienteId={tramiteEnCurso?.id}
          datosAgenda={datosFecha}
          fecha={fecha}
          errorLlamar={errorLlamar}
          setErrorLlamar={setErrorLlamar}
          cargandoDatosAgenda={cargandoDatosAgenda}
          obtenerAgenda={obtenerAgenda}
          tipoTurno={tipoTurno}
        />
      </Card>
    </div>
  );
};

export default AgendaVideollamadaContainer;
