/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

import { hideHeader } from 'actions/ui';
import { fetchAgente } from 'actions/agente';
import { connect, useDispatch } from 'react-redux';

// API
import {
  fetchVideollamadaSoporte,
  rechazarTramiteConsulta,
  desvincularAgenteTramiteConsulta,
} from 'services/apiServices';

import {
  CONFIRMAR_TERMINAR_VIDEOLLAMADA_SOPORTE_TEXT,
  TERMINAR_VIDEOLLAMADA_TEXT,
  VOLVER_A_VIDEOLLAMADA,
  DESVINCULARSE_VIDEOLLAMADA_TEXT,
  VOLVER_BOTON_TEXT,
} from 'constants/commonConstants';

// Components
import Card from 'components/Card';
import CardSpinner from 'components/CardSpinner';
import Button from 'components/Button';
import BotonVolver from 'components/BotonVolver';
import { AGENDA_VIDEOLLAMADA_SOPORTE } from 'constants/RouterConstants';
import RechazarVideollamadaSoporte from './RechazarVideollamadaSoporte';
import { Videollamada } from './videollamada';
import { VideollamadaSoporteInfo } from './Info';

// Styles
import styles from '../Videollamada/Container/index.module.css';

const VideollamadaSoporteContainer = ({ ocultarHeader, agenteLogueado }) => {
  const [cargando, setCargando] = useState(true);
  const [videollamada, setVideollamada] = useState(null);
  const [cargandoColgar, setCargandoColgar] = useState(false);
  const [modalColgarAbierto, setModalColgarAbierto] = useState(false);
  const { uuid } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();

  const handleObtenerVideollamada = async () => {
    try {
      const response = await fetchVideollamadaSoporte(uuid);
      setVideollamada(response.data);
      setCargando(false);
    } catch (e) {
      setVideollamada(null);
    }
  };

  useEffect(() => {
    ocultarHeader();
    handleObtenerVideollamada();
  }, []);

  const handleColgarLlamada = async data => {
    setCargandoColgar(true);
    try {
      await rechazarTramiteConsulta(
        videollamada.id,
        data.resultado,
        data.resultadoExtra,
      );
      setCargandoColgar(false);
      setModalColgarAbierto(false);
      dispatch(fetchAgente());
      history.push(AGENDA_VIDEOLLAMADA_SOPORTE);
    } catch {
      setCargandoColgar(false);
      setModalColgarAbierto(false);
    }
  };

  const handleBotonVolverODesvincularse = async () => {
    try {
      await desvincularAgenteTramiteConsulta(uuid);
    } finally {
      dispatch(fetchAgente());
      history.push(AGENDA_VIDEOLLAMADA_SOPORTE);
    }
  };

  const botonColgar = (
    <Button
      type="secondary"
      size="lg"
      title={TERMINAR_VIDEOLLAMADA_TEXT}
      className={styles.boton_cancelar}
      callback={() => setModalColgarAbierto(true)}
    />
  );

  const botonDesvincularse = (
    <Button
      type="secondary"
      size="lg"
      title={DESVINCULARSE_VIDEOLLAMADA_TEXT}
      className={styles.boton_secundario}
      callback={handleBotonVolverODesvincularse}
    />
  );

  if (cargando) {
    return <CardSpinner spin />;
  }

  return (
    <div>
      <BotonVolver
        texto={VOLVER_BOTON_TEXT}
        handlePrevious={handleBotonVolverODesvincularse}
      />
      <div className={styles.contenedor}>
        <Card className={styles.contenedorInformacion}>
          <VideollamadaSoporteInfo videollamada={videollamada} />
          <div className={styles.botonesContenedor}>
            {botonDesvincularse}
            {botonColgar}
          </div>
          <RechazarVideollamadaSoporte
            onSubmit={handleColgarLlamada}
            cargandoRechazar={cargandoColgar}
            onCancel={() => setModalColgarAbierto(false)}
            onClose={() => setModalColgarAbierto(false)}
            modalAbierto={modalColgarAbierto}
            textoModal={CONFIRMAR_TERMINAR_VIDEOLLAMADA_SOPORTE_TEXT}
            textoConfirmar={TERMINAR_VIDEOLLAMADA_TEXT}
            textoCancelar={VOLVER_A_VIDEOLLAMADA}
          />
        </Card>
        <Card className={styles.contenedorVideollamada}>
          <Videollamada
            videollamada={videollamada}
            nombreAgente={agenteLogueado}
          />
        </Card>
      </div>
    </div>
  );
};

VideollamadaSoporteContainer.propTypes = {
  ocultarHeader: PropTypes.func,
  agenteLogueado: PropTypes.string,
};

const mapStateToProps = state => ({
  agenteLogueado: state.agente?.currentAgenteNombre,
});

export default connect(mapStateToProps, {
  fetchAgente,
  ocultarHeader: hideHeader,
})(VideollamadaSoporteContainer);
