import React, { useState } from 'react';
import PropTypes from 'prop-types';

// permisos
import usePermissions from 'hooks/usePermissions';

// assets
import buttonMore from 'assets/icons/button-more.png';
import buttonMore2x from 'assets/icons/button-more@2x.png';
import buttonMore3x from 'assets/icons/button-more@3x.png';

// constantes
import {
  CIUDADANOS_LINK,
  CERRAR_SESION_LINK,
  AGENDA_DROPDOWN,
  AGENDA_VALIDACION_LINK,
  AGENDA_CONSULTA_LINK,
  TURNOS_DROPDOWN,
  TURNOS_VALIDACION_LINK,
  TURNOS_CONSULTA_LINK,
  TEXTO_CERRAR_SESION,
  GESTION_AGENTES_LINK,
} from 'constants/commonConstants';
import {
  PERMISO_GESTIONAR_CONFIGURACION_TURNO,
  PERMISO_HACER_TRAMITE_VIDEOLLAMADA,
  PERMISO_PUEDE_ACCEDER_GESTION_AGENTES,
} from 'constants/permissionsConstants';

// componentes
import ButtonIcon from '../ButtonIcon';
import ButtonMenuNavbar from '../ButtonMenuNavbar';
import ButtonFlat from '../ButtonFlat';

// styles
import styles from '../../index.module.css';
import stylesHeader from './styles.module.css';

const HeaderMenuInterno = ({
  menuAbierto,
  mostrarMasOpcionesTooltip,
  handleAbrirMenu,
  handleAbrirTooltip,
  handleIrAGestionAgentes,
  handleIrAInicio,
  handleIrAAgendaVideollamadaValidacion,
  handleIrAConfiguracionTurnosValidacion,
  handleIrAAgendaVideollamadaSoporte,
  handleIrAConfiguracionTurnosSoporte,
  handleIrALogout,
}) => {
  const puedeGestionarConfiguracionTurno = usePermissions([
    PERMISO_GESTIONAR_CONFIGURACION_TURNO,
  ]);
  const puedeHacerTramiteVideollamada = usePermissions([
    PERMISO_HACER_TRAMITE_VIDEOLLAMADA,
  ]);
  const puedeAccederGestionAgentes = usePermissions([
    PERMISO_PUEDE_ACCEDER_GESTION_AGENTES,
  ]);
  const [tooltipAgendaAbierta, setTooltipAgendaAbierta] = useState();
  const [tooltipGestionAbierto, setTooltipGestionAbierto] = useState();
  return (
    <div className={stylesHeader.headerInterno}>
      <div className={styles['hidden-desktop']}>
        <ButtonMenuNavbar
          isMenuOpen={menuAbierto}
          handleOpenMenu={handleAbrirMenu}
        />
        {menuAbierto && (
          <div className={stylesHeader.headerNav}>
            <ButtonFlat
              text={CIUDADANOS_LINK}
              handleOnClick={handleIrAInicio}
              className={stylesHeader.headerNav__item}
              shortLineHeight
            />
            {puedeAccederGestionAgentes && (
              <ButtonFlat
                text={GESTION_AGENTES_LINK}
                handleOnClick={handleIrAGestionAgentes}
                className={stylesHeader.headerNav__item}
                shortLineHeight
              />
            )}
            {puedeGestionarConfiguracionTurno && (
              <ButtonFlat
                text={TURNOS_VALIDACION_LINK}
                handleOnClick={handleIrAConfiguracionTurnosValidacion}
                className={stylesHeader.headerNav__item}
                shortLineHeight
              />
            )}
            {puedeHacerTramiteVideollamada && (
              <ButtonFlat
                text={AGENDA_VALIDACION_LINK}
                handleOnClick={handleIrAAgendaVideollamadaValidacion}
                className={stylesHeader.headerNav__item}
                shortLineHeight
              />
            )}
            {puedeGestionarConfiguracionTurno && (
              <ButtonFlat
                text={TURNOS_CONSULTA_LINK}
                handleOnClick={handleIrAConfiguracionTurnosSoporte}
                className={stylesHeader.headerNav__item}
                shortLineHeight
              />
            )}
            {puedeHacerTramiteVideollamada && (
              <ButtonFlat
                text={AGENDA_CONSULTA_LINK}
                handleOnClick={handleIrAAgendaVideollamadaSoporte}
                className={stylesHeader.headerNav__item}
                shortLineHeight
              />
            )}
            <ButtonFlat
              text={CERRAR_SESION_LINK}
              handleOnClick={handleIrALogout}
              className={stylesHeader.headerNav__item}
              shortLineHeight
            />
          </div>
        )}
      </div>
      <div className={`${stylesHeader.headerNav} ${styles['hidden-tablet']}`}>
        <ButtonFlat
          text={CIUDADANOS_LINK}
          handleOnClick={handleIrAInicio}
          className={stylesHeader.botonLink}
          shortLineHeight
        />
        {puedeAccederGestionAgentes && (
          <ButtonFlat
            text={GESTION_AGENTES_LINK}
            handleOnClick={handleIrAGestionAgentes}
            className={stylesHeader.botonLink}
            shortLineHeight
          />
        )}
        {puedeGestionarConfiguracionTurno && (
          <ButtonFlat
            text={TURNOS_DROPDOWN}
            handleOnMouseEnter={() => setTooltipGestionAbierto(true)}
            handleOnMouseLeave={() => setTooltipGestionAbierto(false)}
            className={stylesHeader.botonLink}
            shortLineHeight
          />
        )}
        {tooltipGestionAbierto && (
          <div
            className={stylesHeader.headerNavTurnos__tooltip}
            onMouseEnter={() => setTooltipGestionAbierto(true)}
            onMouseLeave={() => setTooltipGestionAbierto(false)}
          >
            <button
              className={stylesHeader.headerNav__tooltip__text}
              onClick={handleIrAConfiguracionTurnosValidacion}
              type="button"
            >
              {TURNOS_VALIDACION_LINK}
            </button>
            <button
              className={stylesHeader.headerNav__tooltip__text}
              onClick={handleIrAConfiguracionTurnosSoporte}
              type="button"
            >
              {TURNOS_CONSULTA_LINK}
            </button>
          </div>
        )}
        {puedeHacerTramiteVideollamada && (
          <ButtonFlat
            text={AGENDA_DROPDOWN}
            handleOnMouseEnter={() => setTooltipAgendaAbierta(true)}
            handleOnMouseLeave={() => setTooltipAgendaAbierta(false)}
            className={stylesHeader.botonLink}
            shortLineHeight
          />
        )}
        {tooltipAgendaAbierta && (
          <div
            className={stylesHeader.headerNavVideollamada__tooltip}
            onMouseEnter={() => setTooltipAgendaAbierta(true)}
            onMouseLeave={() => setTooltipAgendaAbierta(false)}
          >
            <button
              className={stylesHeader.headerNav__tooltip__text}
              onClick={handleIrAAgendaVideollamadaValidacion}
              type="button"
            >
              {AGENDA_VALIDACION_LINK}
            </button>
            <button
              className={stylesHeader.headerNav__tooltip__text}
              onClick={handleIrAAgendaVideollamadaSoporte}
              type="button"
            >
              {AGENDA_CONSULTA_LINK}
            </button>
          </div>
        )}
        <span>
          <ButtonIcon
            icon={buttonMore}
            icon2X={buttonMore2x}
            icon3X={buttonMore3x}
            handleOnClick={handleAbrirTooltip}
          />
        </span>
      </div>
      {mostrarMasOpcionesTooltip && (
        <div className={stylesHeader.headerNav__tooltip}>
          <button
            className={stylesHeader.headerNav__tooltip__text}
            onClick={handleIrALogout}
            type="button"
          >
            {TEXTO_CERRAR_SESION}
          </button>
        </div>
      )}
    </div>
  );
};

HeaderMenuInterno.propTypes = {
  menuAbierto: PropTypes.bool,
  mostrarMasOpcionesTooltip: PropTypes.bool,
  handleAbrirMenu: PropTypes.func,
  handleAbrirTooltip: PropTypes.func,
  handleIrAGestionAgentes: PropTypes.func,
  handleIrAInicio: PropTypes.func,
  handleIrAAgendaVideollamadaValidacion: PropTypes.func,
  handleIrAConfiguracionTurnosValidacion: PropTypes.func,
  handleIrAAgendaVideollamadaSoporte: PropTypes.func,
  handleIrAConfiguracionTurnosSoporte: PropTypes.func,
  handleIrALogout: PropTypes.func,
};

export default HeaderMenuInterno;
