/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { isEmpty, isUndefined } from 'lodash';

import {
  cancelarTramite,
  cancelarTramiteConsulta,
  siguienteTramiteVideollamada,
  siguienteTramiteVideollamadaConsulta,
} from 'services/apiServices';

// componentes
import CardSpinner from 'components/CardSpinner';
import Tooltip from 'components/Tooltip';
import ModalConfirmarAccion from 'components/ModalConfirmarAccion';
import Snackbar from 'components/Snackbar';
import Button from 'components/Button';

// constantes
import {
  LLAMAR_TEXT,
  TEXTO_TOOLTIP_CANCELAR_VIDEOLLAMADA_DISABLED,
  TEXTO_MODAL_CANCELAR_VIDEOLLAMADA,
  TEXTO_MODAL_CANCELAR_VIDEOLLAMADA_CONSULTA,
  TEXTO_BOTON_CANCELAR_VIDEOLLAMADA,
  TEXTO_BOTON_VOLVER_AGENDA,
  TEXTO_SNACKBAR_CANCELAR_EXITO,
  TEXTO_SNACKBAR_CANCELAR_ERROR,
  ESTADO_AGENDADO,
  TEXTO_SIN_AGENTE_ASIGNADO,
  TEXTO_AGENTE_BORRADO,
  ESTADO_RECHAZADO_AGENTE,
  ESTADO_PENDIENTE_AGENTE,
  ESTADO_PENDIENTE_VERIFICACION,
  ESTADO_RECHAZADO_VERIFICACION,
  ESTADO_ERROR,
  ESTADO_PENDIENTE_USUARIO,
  ESTADO_EXITOSO,
  ESTADO_RECHAZADO_USUARIO,
  ESTADO_NO_EXITOSO,
  TEXTO_VIDEOLLAMADA_PENDIENTE,
  TEXTO_VIDEOLLAMADA_FINALIZADO,
  WARNING_VIDEOLLAMADA_TEMPRANA_MINUTOS,
  TEXTO_BOTON_ENTRAR_TEMPRANO,
  textoModalEntrarTempranoVideollamada,
  INGRESO_TEMPRANO,
  TIPO_TURNO_VALIDACION,
  TIPO_TURNO_CONSULTA,
} from 'constants/commonConstants';

// MUI
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';

import iconoInfo from 'assets/icons/icono-info.svg';
import { datosAgendaCompletos, columnas, celdasTramiteInfo } from './utils';

// styles
import styles from './styles.module.css';
import { ColumnaEstado } from './ColumnaEstado';

const ESTADOS_VIDEOLLAMADA_EN_CURSO = [
  ESTADO_AGENDADO,
  ESTADO_PENDIENTE_VERIFICACION,
  ESTADO_PENDIENTE_AGENTE,
];

const ESTADOS_FINALIZADA_VALIDACION = [
  ESTADO_RECHAZADO_AGENTE,
  ESTADO_RECHAZADO_USUARIO,
];
const ESTADOS_FINALIZADA_CONSULTA = [ESTADO_EXITOSO, ESTADO_NO_EXITOSO];

const ESTADOS_PUEDE_CANCELAR_VALIDACION = {
  [ESTADO_AGENDADO]: true,
  [ESTADO_PENDIENTE_VERIFICACION]: true,
  [ESTADO_PENDIENTE_AGENTE]: true,
  [ESTADO_RECHAZADO_VERIFICACION]: true,
  [ESTADO_ERROR]: true,
};

const ESTADOS_PUEDE_CANCELAR_CONSULTA = {
  [ESTADO_AGENDADO]: true,
  [ESTADO_PENDIENTE_USUARIO]: true,
  [ESTADO_ERROR]: true,
};

const AgendaVideollamadaTabla = ({
  datosAgenda,
  fecha,
  setErrorLlamar,
  cargandoDatosAgenda,
  obtenerAgenda,
  agenteTramitePendienteId,
  tipoTurno,
}) => {
  const [
    cancelarVideollamadaModalAbierto,
    setCancelarVideollamadaModalAbierto,
  ] = useState(false);
  const [
    entrarVideollamadaTempranoModalAbierto,
    setEntrarVideollamadaTempranoModalAbierto,
  ] = useState(false);
  const [cargandoLlamar, setCargandoLlamar] = useState({});
  const [cargandoCancelar, setCargandoCancelar] = useState(false);
  const [snackBarInfo, setSnackBarInfo] = useState({
    mostrar: false,
    texto: '',
  });
  const [tramiteSeleccionadoCancelar, setTramiteSeleccionadoCancelar] =
    useState(false);
  const [horaSeleccionada, setHoraSeleccionada] = useState();

  const history = useHistory();

  let botonLlamarRenderizado = false;

  const estadosConAgente = [
    ESTADO_EXITOSO,
    ESTADO_RECHAZADO_AGENTE,
    ESTADO_RECHAZADO_USUARIO,
    ESTADO_RECHAZADO_VERIFICACION,
    ...(tipoTurno === TIPO_TURNO_VALIDACION ? [ESTADO_PENDIENTE_USUARIO] : []),
  ];

  const handleLlamarSiguiente = async hora => {
    try {
      setCargandoLlamar({ ...cargandoLlamar, [hora]: true });
      const response =
        tipoTurno === TIPO_TURNO_VALIDACION
          ? await siguienteTramiteVideollamada(fecha, hora)
          : await siguienteTramiteVideollamadaConsulta(fecha, hora);
      setCargandoLlamar({ ...cargandoLlamar, [hora]: false });
      if (tipoTurno === TIPO_TURNO_VALIDACION)
        history.push(`/videollamada/${response?.data?.tramite}`);
      if (tipoTurno === TIPO_TURNO_CONSULTA)
        history.push(`/videollamada-soporte/${response?.data?.tramite}`);
    } catch (error) {
      if (error.status === 404) {
        setCargandoLlamar({ ...cargandoLlamar, [hora]: false });
        setErrorLlamar({
          error: true,
          fecha: dayjs(fecha).locale('es').format('D [d]e MMMM [d]e YYYY'),
          hora,
        });
      }
    }
  };

  const quizasLlamarSiguiente = hora => {
    const datetimeLlamada = dayjs(`${fecha} ${hora}`, 'YYYY-MM-DD HH:mm');
    const diferenciaMinutos = datetimeLlamada.diff(dayjs(), 'minute');

    if (diferenciaMinutos >= WARNING_VIDEOLLAMADA_TEMPRANA_MINUTOS) {
      setHoraSeleccionada(hora);
      setEntrarVideollamadaTempranoModalAbierto(true);
    } else {
      handleLlamarSiguiente(hora);
    }
  };

  const celdaHora = (key, mostrar) => (
    <td key="hora" className={styles.horaTurno}>
      {mostrar && key}
    </td>
  );

  const celdaLlamar = (hora, tramite) => {
    if (tipoTurno === TIPO_TURNO_CONSULTA) botonLlamarRenderizado = false;
    const existeAgendado = datosAgenda[hora].some(
      llamada =>
        llamada.estado === ESTADO_AGENDADO &&
        llamada.agente === null &&
        llamada.id === tramite.id,
    );

    const puedeLlamar =
      !cargandoLlamar[hora] &&
      existeAgendado &&
      isUndefined(agenteTramitePendienteId);
    const estaCargandoLlamar = cargandoLlamar[hora] && existeAgendado;

    let textoSinBoton =
      (tipoTurno === TIPO_TURNO_VALIDACION &&
        ESTADOS_FINALIZADA_VALIDACION.includes(tramite.estado)) ||
      (tipoTurno === TIPO_TURNO_CONSULTA &&
        ESTADOS_FINALIZADA_CONSULTA.includes(tramite.estado))
        ? TEXTO_VIDEOLLAMADA_FINALIZADO
        : TEXTO_VIDEOLLAMADA_PENDIENTE;

    if (tramite.agente === null) {
      textoSinBoton = '';
    }

    if (botonLlamarRenderizado || tramite.agente !== null) {
      return <td className={styles.textoSinBoton}>{textoSinBoton}</td>;
    }

    if (existeAgendado) {
      botonLlamarRenderizado = true;
    }

    return (
      <td key="boton">
        {puedeLlamar ? (
          <Button
            key={hora}
            type="secondary"
            size="lg"
            title={LLAMAR_TEXT}
            className={styles.botonLlamar}
            callback={() => quizasLlamarSiguiente(hora)}
          />
        ) : (
          estaCargandoLlamar && <CircularProgress />
        )}
      </td>
    );
  };

  const handleAbrirModalCancelar = tramite => {
    setTramiteSeleccionadoCancelar(tramite);
    setCancelarVideollamadaModalAbierto(true);
  };

  const getAgente = tramite => {
    if (tramite.agente?.nombre_completo) return tramite.agente?.nombre_completo;
    if (estadosConAgente.includes(tramite.estado)) return TEXTO_AGENTE_BORRADO;
    return TEXTO_SIN_AGENTE_ASIGNADO;
  };

  const celdaCancelar = tramite => {
    const esAgenteTramite =
      !isEmpty(tramite.agente) && agenteTramitePendienteId === tramite.id;
    return (
      <td key="cancelar">
        <Tooltip
          title={
            isEmpty(tramite.agente) || esAgenteTramite
              ? TEXTO_BOTON_CANCELAR_VIDEOLLAMADA
              : TEXTO_TOOLTIP_CANCELAR_VIDEOLLAMADA_DISABLED
          }
          placement="bottom"
          arrow
        >
          <CloseIcon
            size="small"
            className={
              isEmpty(tramite.agente) || esAgenteTramite
                ? styles.iconoCancelar
                : styles.iconoCancelarDisabled
            }
            onClick={
              isEmpty(tramite.agente) || esAgenteTramite
                ? () => handleAbrirModalCancelar(tramite)
                : null
            }
          />
        </Tooltip>
      </td>
    );
  };

  const celdasTramite = tramite => {
    const puedeTomarLlamada =
      !ESTADOS_VIDEOLLAMADA_EN_CURSO.includes(tramite.estado) ||
      (ESTADOS_VIDEOLLAMADA_EN_CURSO.includes(tramite.estado) &&
        tramite.agente !== null &&
        agenteTramitePendienteId !== tramite.id);

    const estiloTd = puedeTomarLlamada ? styles.tdDeshabilitado : '';
    return (
      <>
        {celdasTramiteInfo(tipoTurno, tramite).map(({ key, value }) => (
          <td className={estiloTd} key={key}>
            {value}
          </td>
        ))}
        <ColumnaEstado
          tramite={tramite}
          puedeTomarLlamada={puedeTomarLlamada}
          tipoTurno={tipoTurno}
        />
        <td key="agente" className={estiloTd}>
          {getAgente(tramite)}
        </td>
        {tipoTurno === TIPO_TURNO_CONSULTA && (
          <td key="info" className={styles.contenedorInfo}>
            <img src={iconoInfo} alt="Motivo solicitud" />
            <div className={styles.hoverText}>
              {tramite.informacion_contacto.mensaje}
            </div>
          </td>
        )}
      </>
    );
  };

  const mostrarDatosTramites = (hora, tramites) => {
    const datosTramites = tramites.map((tramite, index) => (
      // Solo la primera fila debe contener la hora
      <tr key={`tramite${tramite.id}`} className={styles.fila}>
        {celdaHora(hora, index === 0)}
        {celdasTramite(tramite)}
        {celdaLlamar(hora, tramite)}
        {((tipoTurno === TIPO_TURNO_VALIDACION &&
          ESTADOS_PUEDE_CANCELAR_VALIDACION[tramite?.estado]) ||
          (tipoTurno === TIPO_TURNO_CONSULTA &&
            ESTADOS_PUEDE_CANCELAR_CONSULTA[tramite?.estado])) &&
          celdaCancelar(tramite)}
      </tr>
    ));

    if (datosTramites.length > 0) {
      return datosTramites;
    }
    // Si no hay trámite para una hora, la fila debe contener solo la hora
    return (
      <tr key={hora} className={styles.ultimaFilaDeBloque}>
        {celdaHora(hora, 1)}
      </tr>
    );
  };

  const handleCancelarTramite = async () => {
    try {
      setCargandoCancelar(true);
      if (tipoTurno === TIPO_TURNO_VALIDACION)
        await cancelarTramite(tramiteSeleccionadoCancelar.id);
      else if (tipoTurno === TIPO_TURNO_CONSULTA)
        await cancelarTramiteConsulta(tramiteSeleccionadoCancelar.id);
      setSnackBarInfo({
        mostrar: true,
        texto: TEXTO_SNACKBAR_CANCELAR_EXITO,
      });
    } catch {
      setSnackBarInfo({ mostrar: true, texto: TEXTO_SNACKBAR_CANCELAR_ERROR });
    } finally {
      setCargandoCancelar(false);
      setCancelarVideollamadaModalAbierto(false);
      obtenerAgenda(fecha);
    }
  };

  return (
    <>
      <div className={styles.contenedor}>
        {cargandoDatosAgenda ? (
          <div className={styles.spinner}>
            <CardSpinner
              spin
              text="Cargando..."
              className="spinner__transparent__videollamada"
            />
          </div>
        ) : null}
        <ModalConfirmarAccion
          title={INGRESO_TEMPRANO}
          isOpen={entrarVideollamadaTempranoModalAbierto}
          onClose={() => setEntrarVideollamadaTempranoModalAbierto(false)}
          text={textoModalEntrarTempranoVideollamada(fecha, horaSeleccionada)}
          buttonYesCallback={() => handleLlamarSiguiente(horaSeleccionada)}
          buttonNoCallback={() =>
            setEntrarVideollamadaTempranoModalAbierto(false)
          }
          loading={cargandoLlamar[horaSeleccionada]}
          buttonTextPositiveAction={TEXTO_BOTON_ENTRAR_TEMPRANO}
          buttonTextNegativeAction={TEXTO_BOTON_VOLVER_AGENDA}
        />
        <ModalConfirmarAccion
          isOpen={cancelarVideollamadaModalAbierto}
          onClose={() => setCancelarVideollamadaModalAbierto(false)}
          text={
            tipoTurno === TIPO_TURNO_VALIDACION
              ? TEXTO_MODAL_CANCELAR_VIDEOLLAMADA
              : TEXTO_MODAL_CANCELAR_VIDEOLLAMADA_CONSULTA
          }
          buttonYesCallback={handleCancelarTramite}
          buttonNoCallback={() => setCancelarVideollamadaModalAbierto(false)}
          loading={cargandoCancelar}
          buttonTextPositiveAction={TEXTO_BOTON_CANCELAR_VIDEOLLAMADA}
          buttonTextNegativeAction={TEXTO_BOTON_VOLVER_AGENDA}
        />
        <table className={styles.tabla}>
          <thead>
            <tr className={styles.cabezal}>
              {columnas(tipoTurno).map(({ nombre, grande, celdaVacia }) => {
                let estilo = grande
                  ? styles.primerFilaCeldaGrande
                  : styles.primerFilaCeldaChica;
                if (celdaVacia) {
                  estilo = styles.primerFilaCeldaVacia;
                }
                return <th className={estilo}>{nombre}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {datosAgendaCompletos(datosAgenda).map(([hora, tramites]) =>
              mostrarDatosTramites(hora, tramites),
            )}
          </tbody>
        </table>
      </div>
      <Snackbar
        desplegar={snackBarInfo.mostrar}
        mensaje={snackBarInfo.texto}
        onClose={() => setSnackBarInfo({ ...snackBarInfo, mostrar: false })}
      />
    </>
  );
};

AgendaVideollamadaTabla.propTypes = {
  datosAgenda: PropTypes.object,
  fecha: PropTypes.string,
  setErrorLlamar: PropTypes.func,
  cargandoDatosAgenda: PropTypes.bool,
  obtenerAgenda: PropTypes.func,
  agenteTramitePendienteId: PropTypes.number,
  tipoTurno: PropTypes.string,
};

export default AgendaVideollamadaTabla;
