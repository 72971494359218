import React from 'react';
import PropTypes from 'prop-types';

// Constants
import {
  CORREO_ELECTRONICO_LABEL,
  TEXTO_VIDEOLLAMADA_SOPORTE,
  NOMBRE_COMPLETO_LABEL,
  NUMERO_DOCUMENTO_LABEL,
  NUMERO_TELEFONO_LABEL,
} from 'constants/commonConstants';

// Components
import InfoItem from 'components/InfoItem';

// Styles
import styles from '../Videollamada/Container/index.module.css';

export const VideollamadaSoporteInfo = ({ videollamada }) => {
  const datos = [
    {
      key: 'correo',
      label: CORREO_ELECTRONICO_LABEL,
      content: videollamada.informacion_contacto.correo_electronico,
    },
    {
      key: 'telefono',
      label: NUMERO_TELEFONO_LABEL,
      content: videollamada.informacion_contacto.numero_telefono,
    },
    {
      key: 'nombre_completo',
      label: NOMBRE_COMPLETO_LABEL,
      content: videollamada.informacion_contacto.nombre_completo,
    },
    {
      key: 'nroDocumento',
      label: NUMERO_DOCUMENTO_LABEL,
      content: videollamada.informacion_contacto.numero_documento,
    },
    {
      key: 'asunto',
      label: 'Asunto:',
      content: videollamada.informacion_contacto.asunto,
    },
    {
      key: 'mensaje',
      label: 'Mensaje:',
      content: videollamada.informacion_contacto.mensaje,
    },
  ];

  return (
    <div className={styles.datosUsuario}>
      <h1 className={styles.contenedorInformacion__titulo}>
        {TEXTO_VIDEOLLAMADA_SOPORTE}
      </h1>
      {datos.map(({ key, label, content }) => (
        <InfoItem
          key={key}
          label={label}
          labelClass={styles.labelDatoPersonal}
          content={content}
          contentClass={styles.datoPersonal}
        />
      ))}
    </div>
  );
};

VideollamadaSoporteInfo.propTypes = {
  videollamada: PropTypes.object,
};
