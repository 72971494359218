import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

export const UY_CODE = 'uy';
export const CI_CODE = 68909;
export const PAGE_SIZE = 10;
export const MAX_LEN_NAMES = 100;

export const VALIDADOR_TIPO_ERROR = 'error';
export const VALIDADOR_VAL_ERROR = 10;
export const CI_INVALIDA = 'El documento no es válido.';
export const FORMATO_CI_INVALIDO =
  'Ingrese el documento sin puntos ni guiones.';
export const REQUERIDO = 'Requerido';
export const CORREOS_DEBEN_SER_IGUALES = 'Deben ser iguales';
export const VALID_EMAIL = 'Ingrese un email válido';
export const VALID_NAME = 'No uses símbolos ni números';
export const MAX_LEN_ERROR = `Asegúrese de que este campo no tenga más de ${MAX_LEN_NAMES} caracteres.`;
export const NIVEL_DE_CONFIANZA_CERTIFICADO = 'CERTIFICADO';
export const NIVEL_DE_CONFIANZA_AUTOREGISTRADO = 'AUTOREGISTRADO';
export const obtenerMensajeErrorLargoMaximo = value =>
  `Asegúrese de que este campo no tenga más de ${value} caracteres.`;
export const obtenerMensajeErrorLargoMinimo = value =>
  `Asegúrese de que este campo tenga al menos ${value} caracteres.`;

export const MODAL_VERIFICAR_CIUDADANO_TEXT =
  'Corroborá que los datos del ciudadano a continuación sean los mismos que en su documento';
export const CIUDADANO_NO_VISIBLE =
  'El usuario no ha completado su registro. Debe ingresar a Usuario gub.uy con el metodo que usó (CI Digital o Proveedor de Identidad) y completar el formulario.';
export const MODAL_EMAIL_NO_VALIDADO_CIUDADANO_TEXT =
  'La acción no se puede realizar debido a que el ciudadano no tiene su correo electrónico validado.';
export const MODAL_CUENTA_NO_ACTIVADA_CIUDADANO_TEXT =
  'La acción no se puede realizar debido a que el ciudadano no tiene su cuenta activada.';
export const MODAL_VERIFICAR_MAIL =
  'El sistema enviará un correo al usuario. ¿Estás de acuerdo con esta acción?';
export const MODAL_CONFIRMAR_CAMBIO_DATOS =
  'Estás a punto de modificar información sensible del ciudadano. ¿Estás de acuerdo con esta acción?';
export const CAMBIO_PRESENCIAL_ERROR =
  'Hubo un error al modificar este Ciudadano. Inténtalo mas tarde.';
export const CAMBIO_EMAIL_ERROR =
  'Hubo un error al modificar este Ciudadano. El email ingresado ya se encuentra registrado para otro ciudadano.';
export const MODIFICAR_CAMBIOS_PERSONALES_SUCCESS =
  'La información del ciudadano fue actualizada correctamente';
export const MODIFICAR_CAMBIOS_PERSONALES_ERROR =
  'No se pudo modificar la información del ciudadano';
export const VERIFICAR_EMAIL_SUCCESS = 'El correo fue enviado correctamente';
export const VERIFICAR_EMAIL_ERROR =
  'Error al enviar el correo de verificación';
export const ENVIAR_EMAIL_ERROR = 'Error al enviar el correo';
export const REVERIFICAR_CORREO_LINK_TEXT = 'Reenviar correo de verificación »';
export const REVERIFICAR_CORREO_TEXT = correo =>
  `El ciudadano todavía no verificó la dirección (${correo}).`;
export const ACTIVAR_CUENTA_LINK_TEXT = 'Reenviar correo de activación »';
export const ACTIVAR_CUENTA_TEXT = ' El ciudadano todavía no activó su cuenta.';
export const CUENTA_BLOQUEADA = ' El ciudadano tiene la cuenta bloqueada.';
export const DESBLOQUEO_CUENTA_LINK_TEXT = 'Reenviar correo de desbloqueo »';
export const CIUDADANO_SIN_PASSWORD_TEXT =
  'El ciudadano todavía no agregó una contraseña.';
export const ELIMINAR_CIUDADANO_TEXT = (name, lastName) => (
  <span className="confirm-name">
    <strong>Está a punto de eliminar a {name}. </strong>
    Para confirmar esta acción escribe su primer apellido ({lastName}):
  </span>
);
export const ELIMINAR_USUARIO = 'Eliminar usuario';
export const CIUDADANO_ELIMINADO = fullName => `Se eliminó a ${fullName}.`;
export const CIUDADANO_ELIMINADO_ERROR = fullName =>
  `No se pudo eliminar al ciudadano ${fullName}.`;
export const VERIFICACION_DOS_PASOS_TITLE = 'Verificación en dos pasos';
export const ACTIVIDAD_TITLE = 'Actividad';
export const VERIFICAR_CIUDADANO_EXITO =
  'El ciudadano ha sido verificado con éxito';
export const VERIFICAR_CIUDADANO_ERROR = 'Error al verificar el ciudadano';
export const OBTENER_ACCIONES_CIUDADANO_ERROR =
  'No se han podido obtener las últimas acciones del ciudadano';

// ---------------------------------------------------------------------------
// Tipos de mensajes de error
// ---------------------------------------------------------------------------
export const TITLE_ERROR_404 = 'Te perdiste.';
export const DESCRIPTION_ERROR_404 =
  'La dirección a la que estás tratando de acceder no existe o fue cambiada.';
export const ALT_ERROR_4O4 = 'Imagen de página no encontrada';
export const TITLE_ERROR_SERVER = 'Uy! Algo salió mal.';
export const DESCRIPTION_ERROR_SERVER =
  'No te preocupes que no sos vos, somos nosotros.';
export const ALT_SERVER_ERROR = 'Imagen de error del servidor';
export const NUMERO_POSITIVO_ERROR = 'Debe ser un número positivo';
export const DATO_REQUERIDO = 'Este es un dato requerido';
export const getMensajeErrorLargoMaximo = value =>
  `Asegúrese de que este campo no tenga más de ${value} caracteres.`;

// ---------------------------------------------------------------------------
// Acceso a webapp de agentes
// ---------------------------------------------------------------------------
export const NO_TIENE_PERMISOS_DE_AGENTE =
  'El usuario que estás usando no puede acceder a esta aplicación.';

// ---------------------------------------------------------------------------
// Agentes (solo lectura)
// ---------------------------------------------------------------------------
export const NO_TIENE_PERMISOS_DE_ESCRITURA =
  'No tienes permiso para realizar esta acción.';

// ---------------------------------------------------------------------------
// Videollamada
// ---------------------------------------------------------------------------

export const ERROR_VERIFICACION = 'Ocurrió un error al verificar.';
export const ERROR_TOMAR_CAPTURA = 'No se pudo tomar la captura.';
export const ERROR_NUMERO_SERIE = 'Número de serie incorrecto';
export const ERROR_PRIMER_APELLIDO = 'Error al validar el primer apellido';
export const ERROR_OBTENER_CEDULA = 'No se pudo obtener la cédula';
export const ERROR_ACEPTAR_TRAMITE = 'No se pudo aceptar el trámite';
export const ERROR_RECHAZAR_TRAMITE = 'No se pudo rechazar el trámite';
export const PREFIJO_BASE64 = 'data:image/png;base64,';
export const ALT_CEDULA_CIUDADANO = 'Cédula del ciudadano';
export const NOMBRE_AGENTE = 'Agente';
export const MENSAJE_NO_VERIFICADO = `No se pudo validar la identidad. 
Asegúrate de que el usuario tenga buena luz, no tenga accesorios o elementos que cubran su cara y vuelve a intentar.`;
export const MENSAJE_VERIFICADO =
  'La identidad se ha podido validar con éxito. ¿Desea aceptar la solicitud del ciudadano?';
export const TOMAR_CAPTURA = 'Tomar captura';
export const OBTENER_NIVEL_INTERMEDIO = 'Obtener nivel de seguridad Intermedio';
export const LARGO_NUMERO_SERIE = 6;
export const NUMERO_SERIE_CEDULA_LABEL =
  'Número de serie de la cédula con chip';
export const NUMERO_SERIE_CEDULA_PLACEHOLDER = 'Ej: 0123RT';
export const CARGAR_CEDULA = 'Cargar cédula';
export const NOMBRE_COMPLETO_LABEL = 'Nombre completo:';
export const TIPO_DOCUMENTO_LABEL = 'Tipo de documento:';
export const NUMERO_DOCUMENTO_LABEL = 'Número de documento:';
export const PAIS_EMISOR_LABEL = 'Pais emisor:';
export const CORREO_ELECTRONICO_LABEL = 'Correo electrónico:';
export const NUMERO_TELEFONO_LABEL = 'Número de teléfono:';
export const NIVEL_LABEL = 'Nivel:';

export const LLAMAR_TEXT = 'Llamar';
export const TERMINAR_VIDEOLLAMADA_TEXT = 'Terminar videollamada';
export const DESVINCULARSE_VIDEOLLAMADA_TEXT = 'Salir de Videollamada';
export const LLAMAR_SIGUIENTE_TEXT = 'Llamar Siguiente';
export const mensajeBienvenido = nombre => `Hola ${nombre}, esta es tu agenda`;
export const TITULO_VIDEOLLAMADA = 'Videollamadas';
export const TITULO_VIDEOLLAMADA_SOPORTE = 'Videollamadas de soporte';
export const ACEPTAR_SOLICITUD_TEXT = 'Aceptar';
export const RECHAZAR_SOLICITUD_TEXT = 'Rechazar';
export const SOLICITUD_RECHAZADA_TEXT =
  'No se pudo validar la identidad. Deberá presentarse en un centro PAC para continuar el trámite.';
export const obtenerSolicitudAceptadaText = nombre =>
  `${nombre} obtuvo un nivel de seguridad intermedio. Recibirá un mail para confirmar el trámite.`;
export const IR_AGENDA_TEXT = 'Ir a mi agenda';
export const CONFIRMAR_TERMINAR_VIDEOLLAMADA_TEXT =
  '¿Está seguro que desea colgar esta videollamada? El trámite será rechazado y no será posible retornar.';
export const CONFIRMAR_RECHAZAR_VIDEOLLAMADA_TEXT =
  '¿Está seguro que desea rechazar esta videollamada? El ciudadano deberá agendarse nuevamente para volver a intentarlo.';
export const CONFIRMAR_TERMINAR_VIDEOLLAMADA_SOPORTE_TEXT =
  '¿Está seguro que desea colgar esta videollamada? El trámite finalizará y no será posible retornar.';
export const CONTINUAR_VIDEOLLAMADA_TEXT = 'Continuar videollamada';
export const VIDEOLLAMADA_EN_CURSO_TEXT =
  'Tienes una videollamada en curso. Presiona el botón para ingresar nuevamente';

export const obtenerTextoVideollamadaEnCurso = cedula => [
  `Tu llamada con CI ${cedula} está`,
  'en curso.',
];
export const RETOMAR_TEXT = 'Retomar.';
export const EN_CURSO_TEXT = 'En curso';
export const errorLlamarText = (fecha, hora) =>
  `Todas las videollamadas para el horario del ${fecha} a las ${hora} han sido tomadas por otros agentes.`;
export const EXPLICAR_MOTIVO_RECHAZO =
  'Explique aquí cualquier comentario extra respecto al motivo del rechazo';
export const MOTIVO_RECHAZO = 'Comentarios extras';
export const VOLVER_A_VIDEOLLAMADA = 'Volver a la videollamada';
export const RECHAZAR_TRAMITE = 'Rechazar el trámite';
export const CARGANDO = 'Cargando...';
export const AGUARDAR_PARTICIPANTES =
  'Ambos participantes deben estar en la llamada para poder tomar la captura';
export const INICIANDO_PROCESO = 'Iniciando proceso de verificación facial...';
export const VERIFICANDO_ESTADO =
  'Calculando el resultado del reconocimiento facial. Este proceso puede tardar unos minutos.';
export const TEXTO_TOOLTIP_CANCELAR_VIDEOLLAMADA_DISABLED =
  'No puedes cancelar una videollamada asignada a otro agente.';
export const TEXTO_MODAL_CANCELAR_VIDEOLLAMADA =
  '¿Está seguro que desea cancelar esta videollamada? El ciudadano será notificado vía email y deberá agendarse nuevamente para volver a intentarlo.';
export const TEXTO_MODAL_CANCELAR_VIDEOLLAMADA_CONSULTA =
  '¿Está seguro que desea cancelar esta videollamada? La persona deberá agendarse nuevamente para volver a intentarlo.';
export const TEXTO_BOTON_CANCELAR_VIDEOLLAMADA = 'Cancelar videollamada';
export const TEXTO_BOTON_VOLVER_AGENDA = 'Volver a la agenda';
export const TEXTO_VIDEOLLAMADA_EN_CURSO = 'Tienes una videollamada en curso';
export const TEXTO_TODAS_VIDEOLLAMADAS_TOMADAS =
  'Todas las videollamadas en este horario han sido tomadas por otros agentes.';
export const TEXTO_SNACKBAR_CANCELAR_EXITO =
  'Se ha cancelado exitosamente el trámite';
export const TEXTO_SNACKBAR_CANCELAR_ERROR =
  'Ha ocurrido un error al cancelar el trámite';
export const TEXTO_SIN_AGENTE_ASIGNADO = 'Pendiente';
export const TEXTO_AGENTE_BORRADO = 'Agente Borrado';
export const obtenerTextoAgendaInfoExitososRechazados = (
  cantidadExitosos,
  cantidadRechazados,
) => `Exitosos: ${cantidadExitosos}, Rechazados: ${cantidadRechazados}`;

export const TEXTO_VIDEOLLAMADA_NAVEGADOR_INCOMPATIBLE = [
  'Este navegador no es compatible con la videollamada. Por favor ',
  'ingrese con otro navegador.',
];
export const obtenerTextoAgendaInfoExitosos = cantidadExitosos =>
  `Exitosos: ${cantidadExitosos}`;
export const obtenerTextoAgendaInfoRechazados = cantidadRechazados =>
  `Rechazados: ${cantidadRechazados}`;
export const obtenerTextoAgendaInfoAgendados = cantidadAgendados =>
  `Agendados: ${cantidadAgendados}`;
export const TEXTO_AGENDA_INFO_SIN_AGENDA = 'sin llamadas';
export const obtenerTextoAgendaInfoSemanaDesdeHasta = (
  primerDiaSemana,
  ultimoDiaSemana,
) => `Desde el ${primerDiaSemana}, hasta el ${ultimoDiaSemana}`;
export const TEXTO_CAMBIAR_LADO_CEDULA = 'Cambiar lado';
export const TEXTO_VIDEOLLAMADA_SOPORTE = 'Videollamada de soporte ';

// ---------------------------------------------------------------------------
// Entrada temprano a videollamada
// ---------------------------------------------------------------------------
export const INGRESO_TEMPRANO = 'Ingreso temprano a videollamada';
export const WARNING_VIDEOLLAMADA_TEMPRANA_MINUTOS = 10;
export const TEXTO_BOTON_ENTRAR_TEMPRANO = 'Ingresar';
export const textoModalEntrarTempranoVideollamada = (fecha, hora) =>
  `La llamada está agendada para el día ${fecha}, a la hora ${hora}. ¿Desea ingresar de todas formas?`;

export const TEXTO_SELECCIONAR_DIA = 'Selecciona un día para ver la agenda';
export const TEXTO_HOY = 'Hoy';
export const TEXTO_VIDEOLLAMADA_PENDIENTE = 'Pendiente';
export const TEXTO_VIDEOLLAMADA_FINALIZADO = 'Finalizado';

// ---------------------------------------------------------------------------
// Estados videollamada nombres
// ---------------------------------------------------------------------------
export const ESTADO_RECHAZADO_AGENTE = 'Rechazado agente';
export const ESTADO_RECHAZADO_USUARIO = 'Rechazado usuario';
export const ESTADO_EXITOSO = 'Exitoso';
export const ESTADO_AGENDADO = 'Agendado';
export const ESTADO_PENDIENTE_AGENTE = 'Pendiente agente';
export const ESTADO_PENDIENTE_VERIFICACION = 'Pendiente verificación';
export const ESTADO_PENDIENTE_USUARIO = 'Pendiente usuario';
export const ESTADO_RECHAZADO_VERIFICACION = 'Rechazado verificación';
export const ESTADO_ERROR = 'Error';
export const ESTADO_NO_EXITOSO = 'No exitoso';

export const CANTIDAD_EXITOSOS = 'exitosos';
export const CANTIDAD_RECHAZADOS = 'rechazados';
export const CANTIDAD_AGENDADOS = 'agendados';

// ---------------------------------------------------------------------------
// Estados Videollamada
// ---------------------------------------------------------------------------

export const PENDIENTE_AGENTE = 2;
export const RECHAZADO_VERIFICACION = 5;
export const ERROR = 10;

export const MOTIVO_RECHAZO_1 =
  'No estaban las condiciones dadas para la videollamada (cámara, contraste, contexto, micrófono, etc) (paso 1)';
export const MOTIVO_RECHAZO_2 =
  'No se pudieron obtener los datos de la DNIC (paso 2)';
export const MOTIVO_RECHAZO_3 =
  'El usuario no tenía su cédula de identidad (paso 2)';
export const MOTIVO_RECHAZO_4 =
  'El usuario no dijo los datos correctos (paso 2)';
export const MOTIVO_RECHAZO_5 =
  'Problemas en la cámara, micrófono o conexión (en cualquier momento)';
export const MOTIVO_RECHAZO_6 =
  'La verificación biométrica no fue exitosa (paso 3)';
export const MOTIVO_RECHAZO_7 = 'El usuario no pasó la prueba de vida (paso 3)';
export const MOTIVO_RECHAZO_8 = 'Otros';
export const MOTIVO_RECHAZO_9 = 'El usuario no se conectó';

export const MOTIVO_RECHAZO_1_TEXTO_TOOLTIP =
  'Condiciones inadecuadas para la videollamada';
export const MOTIVO_RECHAZO_2_TEXTO_TOOLTIP =
  'No se obtuvieron datos de la DNIC';
export const MOTIVO_RECHAZO_3_TEXTO_TOOLTIP =
  'Ciudadano/a no tenía su cédula de identidad';
export const MOTIVO_RECHAZO_4_TEXTO_TOOLTIP =
  'Ciudadano/a no dijo los datos correctos';
export const MOTIVO_RECHAZO_5_TEXTO_TOOLTIP =
  'Problemas en la cámara, micrófono o conexión';
export const MOTIVO_RECHAZO_6_TEXTO_TOOLTIP =
  'Ciudadano/a no pasó la verificación biométrica';
export const MOTIVO_RECHAZO_7_TEXTO_TOOLTIP =
  'Ciudadano/a no pasó la prueba de vida';
export const MOTIVO_RECHAZO_8_TEXTO_TOOLTIP = 'Otros';
export const MOTIVO_RECHAZO_9_TEXTO_TOOLTIP = 'Ciudadano/a no se conectó';

export const MOTIVO_RECHAZO_OBLIGATORIO = 'Seleccione un motivo de rechazo';

export const RESULTADO_VIDEOLLAMADA_SOPORTE_1 = 'La videollamada fue exitosa';
export const RESULTADO_VIDEOLLAMADA_SOPORTE_2 =
  'La videollamada no fue exitosa';

export const RESULTADO_VIDEOLLAMADA_SOPORTE_OBLIGATORIO =
  'Seleccione un resultado para la videollamada';

export const RESULTADO_VIDEOLLAMADA_EXTRA = 'Comentarios extras';
export const EXPLICAR_RESULTADO_VIDEOLLAMADA_EXTRA =
  'Explique aquí cualquier comentario extra respecto al motivo del rechazo';

// ---------------------------------------------------------------------------
// Header
// ---------------------------------------------------------------------------
export const CIUDADANOS_LINK = 'Ciudadanos';
export const AGENDA_DROPDOWN = 'Agenda Videollamada';
export const AGENDA_VALIDACION_LINK = 'Agenda Validación';
export const AGENDA_CONSULTA_LINK = 'Agenda Soporte';
export const TURNOS_DROPDOWN = 'Gestión de Turnos';
export const TURNOS_VALIDACION_LINK = 'Turnos Validación';
export const TURNOS_CONSULTA_LINK = 'Turnos Soporte';
export const GESTION_AGENTES_LINK = 'Gestión Agentes';
export const CERRAR_SESION_LINK = 'Cerrar sesión';
export const bievenidoAgente = nombre => `Bienvenido/a ${nombre}`;
export const TEXTO_GUB_UY = 'gub.uy';
export const TEXTO_AGENTES = 'Agentes';
export const TEXTO_CERRAR_SESION = 'Cerrar sesión';

// ---------------------------------------------------------------------------
// Crear Configuracion turno
// ---------------------------------------------------------------------------
export const CONFIGURACION_AGENDA_TEXT = 'Configuración de Agenda';
export const CONFIGURACION_AGENDA_DESC =
  'Elige la nueva disponibilidad de horarios para Videollamadas';

// ---------------------------------------------------------------------------
// Crear Configuracion turno - formulario
// ---------------------------------------------------------------------------
export const ELEGIR_DIAS_TEXT = 'Elige días y horarios disponibles';
export const ELEGIR_DIAS_DESC = `La duración estimada de la videollamada es de ${window.REACT_APP_DURACION_VIDEOLLAMADA} minutos.`;
export const SELECCIONE_FECHAS_TEXT = 'Seleccione las fechas';
export const SELECCIONE_HORARIOS_TEXT = 'Seleccione los horarios';
export const CANTIDAD_AGENTES_TEXT = 'Cantidad de agentes';
export const CANTIDAD_AGENTES_PLACEHOLDER = 'Ej: 2';
export const CONTINUAR_BOTON_TEXT = 'Continuar';
export const CONFIRMAR_BOTON_TEXT = 'Confirmar';
export const VOLVER_BOTON_TEXT = 'Volver';

// ---------------------------------------------------------------------------
// Crear Configuracion turno - confirmacion
// ---------------------------------------------------------------------------
export const CONFIRMAR_DATOS_TEXT = 'Confirma los datos para continuar';
export const TURNOS_VIDEOLLAMADA_TEXT = 'Turnos de la videollamada';
export const FECHAS_LABEL = 'Fechas:';
export const HORARIO_LABEL = 'Horario:';
export const TURNOS_POR_DIA_LABEL = 'Turnos por día:';
export const AGENTES_POR_DIA_LABEL = 'Agentes por día:';
export const AVISO_CONFIRMAR_TEXT =
  '*Al confirmar los datos los turnos quedarán disponibles para los Ciudadanos.';
export const obtenerFechasTexto = (fechaDesde, fechaHasta) => `${dayjs(
  fechaDesde,
)
  .locale('es')
  .format('DD [d]e MMMM [d]e YYYY')} al
  ${dayjs(fechaHasta).locale('es').format('DD [d]e MMMM [d]e YYYY')}`;
export const obtenerHorasTexto = (horaDesde, horaHasta) =>
  `${horaDesde} hs - ${horaHasta} hs`;

// ---------------------------------------------------------------------------
// Crear Configuracion turno - éxito
// ---------------------------------------------------------------------------
export const obtenerMensajeListo = nombre => `¡Listo, ${nombre}!`;
export const obtenerPrimerParrafo = cantidadTurnosDiarios =>
  `Se crearon ${cantidadTurnosDiarios} turnos para cada uno de los días seleccionados.`;
export const SEGUNDO_PARRAFO_TEXT =
  'La nueva agenda ya está disponible para los ciudadanos. \n' +
  'Si necesitas modificarlo, lo puedes hacer desde Modificar Agenda.';
export const IR_MODIFICAR_AGENDA_TEXT = 'Ir a Modificar Agenda';

// ---------------------------------------------------------------------------
// Listar Configuracion turno
// ---------------------------------------------------------------------------

export const AGENDA_TURNOS = 'Agenda turnos verificación';
export const AGENDA_TURNOS_CONSULTA = 'Agenda turnos soporte';
export const ELIGE_DIA_DISPONIBILIDAD =
  'Elige un día para ver la disponibilidad';
export const CON_AGENDAS = 'Con agendas';
export const ELIMINAR_CONFIGURACION = 'Eliminar';
export const CANCELAR_ELIMINAR_CONFIGURACION = 'Cancelar';
export const ULTIMA_FECHA_DISPONIBLE = 'Última fecha disponible:';
export const CONFIGURAR_NUEVA_AGENDA = 'Configurar nueva agenda';
export const ELIGE_FECHAS_DISPONIBLES = 'Elige nuevas fechas disponibles ';
export const PARA_LOS_CIUDADANOS = 'para los ciudadanos:';
export const NO_SE_PUDO_ELIMINAR = 'No se ha podido eliminar';
export const DIA_CON_AGENDAS = 'Este día tiene agendas';
export const estaSeguroEliminar = fecha =>
  `¿Está seguro que desea eliminar las configuraciones del día ${fecha?.format(
    'DD/MM/YYYY',
  )}?`;
export const estaSeguroEliminarTextoExtraAgendados = cantidadAgendados =>
  `Las ${cantidadAgendados} agendas correspondiente a esta fecha serán canceladas y se les notificará a los ciudadanos.`;
export const cantidadTurnosRegistradosDia = cantidad =>
  `Este día se registraron ${cantidad} turnos para videollamadas.`;

// ---------------------------------------------------------------------------
// Logout
// ---------------------------------------------------------------------------
export const LOGOUT_CERRANDO_SESION = 'Cerrando sesión';

// ---------------------------------------------------------------------------
// Snackbar
// ---------------------------------------------------------------------------
export const SNACKBAR_DURACION = 6000;
export const DEFAULT_SNACKBAR_INFO = { mostrar: false, texto: '' };

// ---------------------------------------------------------------------------
// Profile
// ---------------------------------------------------------------------------
export const TEXTO_CONSULTAR_DNIC = 'Consulta los datos del ciudadano en DNIC';
export const TEXTO_ERROR_CONSULTAR_DNIC =
  'Ocurrió un error al solicitar los datos en la DNIC';
export const TEXTO_NO_ENCONTRADO_CONSULTAR_DNIC =
  'No se han encontrado resultados en la DNIC para la cédula ingresada.';
export const TEXTO_DATOS_CONTACTO = 'Datos de contacto';
export const TEXTO_DATOS_TELEFONO = 'Teléfono';
export const TEXTO_DATOS_PERSONALES = 'Datos personales';
export const TEXTO_DATOS_DNIC = 'Datos DNIC';
export const CREAR_USUARIO_FROM_DNIC = 'Crear Usuario con estos datos';
export const TEXTO_BOTON_MODIFICAR = 'Modificar';
export const TEXTO_BOTON_OBTENER_DATOS = 'Obtener datos';

export const TEXTO_TITULO_CEDULA_CIUDADANO = 'Cédula ciudadano';
export const TEXTO_BOTON_CONSULTAR_CEDULA = 'Obtener cédula';
export const TEXTO_NUMERO_DE_SERIE_INPUT = 'Número de serie:';

export const TEXTO_BOTON_OBTENER_ULTIMAS_ACTIVIDADES = 'Obtener actividades';
export const TEXTO_BOTON_SI = 'Si';
export const TEXTO_BOTON_NO = 'No';
export const TEXTO_PRIMER_NOMBRE = 'Primer nombre:';
export const TEXTO_SEGUNDO_NOMBRE = 'Segundo nombre:';
export const TEXTO_PRIMER_APELLIDO = 'Primer apellido:';
export const TEXTO_SEGUNDO_APELLIDO = 'Segundo apellido:';
export const TEXTO_TIPO_DOCUMENTO = 'Tipo de documento:';
export const TEXTO_PAIS_EMISION = 'País de emisión:';
export const TEXTO_NUMERO_DOCUMENTO = 'Número de documento:';
export const TEXTO_NOMBRE_EN_CEDULA = 'Nombre en cédula:';
export const TEXTO_CORREO_ELECTRONICO = 'Correo electrónico:';
export const TEXTO_TELEFONO = 'Teléfono:';
export const TEXTO_FECHA_ACTIVACION = 'Fecha de activación:';
export const TEXTO_ACTIVADA = 'Activada';
export const TEXTO_DESACTIVADA = 'Desactivada';
export const TEXTO_FECHA = 'Fecha:';
export const TEXTO_ORIGEN = 'Origen:';
export const TEXTO_ULTIMO_INGRESO = 'Último ingreso:';
export const TEXTO_NUNCA_INGRESO = 'Nunca';
export const obtenerTextoEliminarCiudadano = ciudadano =>
  `Eliminar a ${ciudadano}`;
export const TEXTO_GUARDAR_DATOS_PERSONALES = 'Guardar';
export const TEXTO_CANCELAR_GUARDAR_DATOS_PERSONALES = 'Cancelar';
export const TEXTO_MODAL_POSITIVO = 'Si';
export const TEXTO_MODAL_NEGATIVO = 'No';
export const TEXTO_NIVEL_CONFIANZA = 'Nivel actual:';
export const TEXTO_SEGURIDAD = 'Nivel de seguridad';
export const TEXTO_VERIFICAR = 'Verificar';
export const TEXTO_VERIFICAR_AHORA = 'Verificar ahora »';
export const TEXTO_ENTIENDO = 'Entiendo';
export const TEXTO_PERFIL_CIUDADANO = 'Perfil de ciudadano';
export const TEXTO_DOCUMENTO = 'Documento';
export const TEXTO_DATOS_REGISTRO = 'Datos de registro';
export const TEXTO_FRENTE_CEDULA = 'Frente cédula';
export const obtenerTextoVerificarCiudadano = ciudadano =>
  `Verificar a ${ciudadano}`;

// ---------------------------------------------------------------------------
// Modificar número de teléfono
// ---------------------------------------------------------------------------
export const MODIFICAR_NUMERO_TELEFONO_TITULO =
  'Modificá el número de teléfono móvil del ciudadano';
export const MODIFICAR_NUMERO_TELEFONO_DESCRIPCION =
  'Ingresá el nuevo número de teléfono móvil.';
export const MODIFICAR_NUMERO_TELEFONO_BOTON_PRIMARIO = 'Continuar';
export const MODIFICAR_NUMERO_TELEFONO_BOTON_PRIMARIO_ARIA_LABEL =
  'Confirmar nuevo número de teléfono';
export const MODIFICAR_NUMERO_TELEFONO_BOTON_SECUNDARIO = 'Cancelar';
export const MODIFICAR_NUMERO_TELEFONO_BOTON_SECUNDARIO_ARIA_LABEL =
  'Cancelar cambio de número de teléfono';
export const MODIFICAR_NUMERO_TELEFONO_TEXTO_ERROR =
  'No ha sido posible modificar el número de teléfono.';
export const MODIFICAR_NUMERO_TELEFONO_TEXTO_ERROR_VACIO =
  'No ha sido posible modificar el número de teléfono.';
export const MODIFICAR_NUMERO_TELEFONO_TEXTO_EXITO =
  'El número de teléfono del ciudadano ha sido modificado.';
export const MODIFICAR_NUMERO_TELEFONO_TEXTO_INFORMATIVO =
  'Dedicá unos instantes a revisar el número del ciudadano en su perfil para asegurarte de que los cambios se hayan concretado.';
export const MODIFICAR_NUMERO_TELEFONO_EXITO_VOLVER = 'Volver al inicio.';
export const TEXTO_NUMERO_TELEFONO_AVISO =
  'Ingresa el número sin el 0 inicial luego de los dígitos del código país.';

// ---------------------------------------------------------------------------
// Gestión de agentes - Tabla
// ---------------------------------------------------------------------------
export const TEXTO_BUSCA_ADMINISTRA = 'Busca y administra permisos de Agentes';
export const TEXTO_BUSCA_SELECCIONAR = 'Seleccionar';
export const TEXTO_CAMPO_CI = 'CI';
export const TEXTO_CAMPO_NOMBRE_COMPLETO = 'Nombre completo';
export const TEXTO_CAMPO_EMAIL = 'Email institucional';
export const TEXTO_CAMPO_ORGANISMO = 'Organismo';
export const TEXTO_CAMPO_ACTIVO = 'Activo';
export const TEXTO_CAMPO_RESPONSABLE = 'Responsable';
export const TEXTO_CAMPO_ESCRITURA = 'Escritura';
export const TEXTO_CAMPO_VIDEOLLAMADA = 'Videollamada';
export const TEXTO_BOTON_EDITAR = 'Editar';
export const TEXTO_ULTIMO_ACCESO = 'Último acceso';
export const TEXTO_ACCION = 'Acción';
export const TEXTO_TITULO_MODAL_EDITAR_AGENTE = agente =>
  `Editar permisos de ${agente}`;
export const TEXTO_TOOLTIP_ACTIVO =
  'Determina si el agente puede ingresar a la plataforma.';
export const TEXTO_TOOLTIP_RESPONSABLE =
  'Determina si el agente es responsable de algún organismo.';
export const TEXTO_TOOLTIP_ESCRITURA =
  'Determina si el agente puede realizar acciones sobre los ciudadanos.';
export const TEXTO_TOOLTIP_ESCRITURA_NO_PERMITIDO = `${TEXTO_TOOLTIP_ESCRITURA} No es posible editarlo ya que el organismo al cual pertenece el agente no tiene permiso de escritura.`;
export const TEXTO_TOOLTIP_VIDEOLLAMADA =
  'Determina si el agente puede realizar trámites videollamada sobre ciudadanos.';
export const TEXTO_BOTON_SECUNDARIO = 'Cancelar';
export const TEXTO_BOTON_PRIMARIO = 'Confirmar';
export const snackbarEditarPermisosExito = agente =>
  `Se han modificado los permisos de ${agente}`;
export const snackbarEditarPermisosFallo = agente =>
  `Ha ocurrido un error al modificar los permisos de ${agente}`;
export const textoModalCi = ci => `CI: ${ci}`;

// ---------------------------------------------------------------------------
// Gestión de agentes - Filtrado
// ---------------------------------------------------------------------------

export const TEXTO_CEDULA = 'Cédula de identidad';
export const TEXTO_NOMBRE = 'Nombre';
export const TEXTO_ORGANISMO = 'Organismo';
export const TEXTO_PERMISOS = 'Permisos';
export const TEXTO_QUITAR = 'Quitar filtros';
export const TEXTO_NO_SE_ENCONTRARON =
  'No se encontraron agentes con los datos ingresados';

// ---------------------------------------------------------------------------
// Busqueda agentes
// ---------------------------------------------------------------------------

export const TEXTO_TITULO = 'Ciudadanos';
export const TEXTO_ESTADISTICAS_USUARIOS = 'Estadísticas Usuarios';
export const TEXTO_PLACEHOLDER_BARRA_BUSQUEDA =
  'Buscá por nombres, apellidos, Nº de documento o correo electrónico';
export const TEXTO_BOTON_CREAR_CIUDADANO = 'Crear';
export const TEXTO_BOTON_BUSCAR_CIUDADANOS = 'Buscar';
export const TEXTO_LLENAR_INPUT = 'Realice una búsqueda';
export const TEXTO_NOMBRE_COMPLETO = 'Nombre completo';
export const TEXTO_NUMERO_DOCUMENTO_ABREVIADO = 'Nº de documento';
export const TEXTO_EMAIL = 'Correo electrónico';
export const TEXTO_SIN_RESULTADOS = 'No se encontraron resultados.';
export const TEXTO_SIN_RESULTADOS_CEDULA =
  'No se encontraron resultados. Puedes consultar en la DNIC por la cédula ingresada.';
export const TEXTO_SIN_RESULTADOS_BUSQUEDA =
  'Ha ocurrido un error al realizar la búsqueda.';

// ---------------------------------------------------------------------------
// Crear ciudadanos
// ---------------------------------------------------------------------------
export const TITULO_CREAR_CIUDADANO = 'Alta de ciudadano';
export const SUBTITULO_CREAR_CIUDADANO =
  'Complete la información a continuación';
export const LABEL_PRIMER_NOMBRE = 'Primer nombre';
export const LABEL_SEGUNDO_NOMBRE = 'Segundo nombre';
export const LABEL_PRIMER_APELLIDO = 'Primer apellido';
export const LABEL_SEGUNDO_APELLIDO = 'Segundo apellido';
export const LABEL_PAIS_EMISOR = 'País emisor';
export const LABEL_TIPO_DOCUMENTO = 'Tipo de documento';
export const LABEL_DOCUMENTO = 'Número';
export const LABEL_CORREO_ELECTRONICO = 'Correo electrónico';
export const LABEL_REPETIR_CORREO_ELECTRONICO = 'Repetir correo electrónico';
export const TEXTO_BOTON_ALTA = 'Crear ciudadano';
export const PLACEHOLDER_DOCUMENTO = 'Ej. 12345678';
export const REGEX_ERROR_DOCUMENTO = 'Solo se aceptan números';
export const VALIDATION_ERROR_DOCUMENTO = 'Documento inválido';
export const REGEX_ERROR_CORREO = 'Correo inválido';
export const VALIDATION_ERROR_CORREO = 'Los correos ingresados no coinciden';
export const TEXTO_NUMERO_TELEFONO = 'Número de teléfono';
export const TEXTO_OPCIONAL = ' (Opcional)';
export const TEXTO_PLACEHOLDER_NUMERO_TELEFONO = 'Ej. +598 999 999 99';

// ---------------------------------------------------------------------------
// Pagina Error
// ---------------------------------------------------------------------------
export const TEXTO_IR_INICIO = 'Ir al inicio';
export const TEXTO_VOLVER = 'Volver';

// ---------------------------------------------------------------------------
// Niveles de seguridad
// ---------------------------------------------------------------------------
export const NIVEL_SEGURIDAD_BASICO = 'Básico';
export const NIVEL_SEGURIDAD_INTERMEDIO = 'Intermedio';
export const NIVEL_AUTOREGISTRO_CI = 'Autoregistro con CI';
export const NIVEL_AUTOREGISTRADO = 'Autoregistrado';
export const NIVEL_VERIFICADO = 'Verificado';
export const NIVEL_CERTIFICADO = 'Certificado';

export const TEXTO_ELEVADO_POR_FIRMA = ' - Firma Digital';
export const TEXTO_ELEVADO_POR_VIDEOLLAMADA = ' - Trámite Videollamada';
export const TEXTO_ELEVADO_POR_ELEVACION = ' - Elevación por Agente';

// ---------------------------------------------------------------------------
// Tabla acciones ciudadano
// ---------------------------------------------------------------------------
export const TEXTO_TABLA_ACCIONES_FECHA = 'Fecha';
export const TEXTO_TABLA_ACCIONES_ACCION = 'Acción';
export const TEXTO_TABLA_ACCIONES_RESPONSABLE = 'Responsable';
export const TEXTO_TABLA_NO_HAY_REGISTROS =
  'No existen registros de actividades para el ciudadano';

// ---------------------------------------------------------------------------
// Tipos de videollamada
// ---------------------------------------------------------------------------
export const TIPO_TURNO_CONSULTA = 'Consulta';
export const TIPO_TURNO_VALIDACION = 'Validacion';
