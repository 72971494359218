import { axiosAPI } from 'utils/axiosBackend';
import Auth from 'utils/Auth';
import {
  INFO_DNIC,
  RECONOCIMIENTO_FACIAL,
  ACEPTAR_TRAMITE,
  RECHAZAR_TRAMITE,
  SIGUIENTE_TRAMITE_URL,
  DESVINCULAR_AGENTE_TRAMITE_URL,
  ESTADO,
  FETCH_VIDEOLLAMADA_URL,
  CIUDADANO_OBTENER_INFO_DNIC_URL,
  OBTENER_AGENTES_ORGANISMO,
  EDITAR_PERMISOS_AGENTE,
  ORGANISMOS_BASE_URL,
  CANCELAR_TRAMITE_URL,
  CIUDADANOS_CREATE_URL,
  CIUDADANOS_SEARCH_LIST_URL,
  CIUDADANO_UPDATE_EMAIL_URL,
  CIUDADANO_UPDATE_PHONE_URL,
  CIUDADANO_DETAIL_URL,
  REQUEST_RESET_PASSWORD_URL,
  RESEND_ACTIVATION_EMAIL_URL,
  CIUDADANO_FACE_VERIFIED_URL,
  CIUDADANO_ACCIONES_URL,
  CIUDADANO_OBTENER_INFO_DNIC_CON_FOTO,
  ESTADISTICAS_DE_NIVELES_CIUDADANOS_URL,
  ESTADISTICAS_DE_CIUDADANOS_LOGUEADOS_URL,
  ESTADISTICAS_DE_CIUDADANOS_CON_EMAIL_DUPLICADO_URL,
  CONSULTA_VIDEOLLAMADA_BASE_URL,
  FINALIZAR_TRAMITE_CONSULTA,
  SIGUIENTE_TRAMITE_CONSULTA_URL,
  DESVINCULAR_AGENTE_TRAMITE_CONSULTA_URL,
  CANCELAR_TRAMITE_CONSULTA_URL,
} from 'constants/ApiConstants';
import { tipoTurnos } from 'utils/tipoTurnos';

const authHeaders = () => ({
  Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
});

const sendRequest = (method, url, data = {}) =>
  axiosAPI({ method, url, data, headers: authHeaders() })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });

// ---------------------------------------------------------------------------
// Videollamada
// ---------------------------------------------------------------------------
export const fetchVideollamada = id => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: FETCH_VIDEOLLAMADA_URL(id),
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const fetchCedula = (primerApellido, nroCedula, nroSerie, dorso) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: `${INFO_DNIC}`,
    params: {
      primer_apellido: primerApellido,
      cedula: nroCedula,
      numero_serie: nroSerie,
      obtener_cedula: true,
      dorso,
    },
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const validarPersona = (foto, nroSerie, tramiteId) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: `${RECONOCIMIENTO_FACIAL}`,
    data: {
      foto,
      nro_serie: nroSerie,
      tramite_id: tramiteId,
    },
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const estadoTramite = tramiteId => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: `${ESTADO(tramiteId)}`,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const rechazarTramite = (
  tramiteId,
  motivoRechazo,
  motivoRechazoExtra,
) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: RECHAZAR_TRAMITE(tramiteId),
    data: {
      motivo_rechazo: motivoRechazo,
      motivo_rechazo_extra: motivoRechazoExtra,
    },
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const rechazarTramiteConsulta = (
  tramiteId,
  resultado,
  resultadoExtra,
) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: FINALIZAR_TRAMITE_CONSULTA(tramiteId),
    data: {
      resultado,
      resultado_extra: resultadoExtra,
    },
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const aceptarTramite = tramiteId => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: ACEPTAR_TRAMITE(tramiteId),
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const desvincularAgenteTramite = tramiteId => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: DESVINCULAR_AGENTE_TRAMITE_URL(tramiteId),
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const desvincularAgenteTramiteConsulta = tramiteUuid => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: DESVINCULAR_AGENTE_TRAMITE_CONSULTA_URL(tramiteUuid),
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const siguienteTramiteVideollamada = (fecha, hora) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: `${SIGUIENTE_TRAMITE_URL}?fecha=${fecha}&hora=${hora}`,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const siguienteTramiteVideollamadaConsulta = (fecha, hora) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: `${SIGUIENTE_TRAMITE_CONSULTA_URL}?fecha=${fecha}&hora=${hora}`,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const eliminarConfiguracionesTurnoDia = (
  tipoTurno,
  idsConfiguraciones,
) =>
  sendRequest(
    'delete',
    tipoTurnos[tipoTurno].eliminarConfiguracionTurnoDiaUrl,
    { ids: idsConfiguraciones },
  );

export const crearConfiguracionTurno = (
  tipoTurno,
  fechas,
  horaInicio,
  horaFin,
  cantidadAgentes,
) => {
  const data = {
    fechas,
    hora_inicio: horaInicio,
    hora_fin: horaFin,
    cantidad_agentes: cantidadAgentes,
  };
  const url = tipoTurnos[tipoTurno].crearConfiguracionTurnoUrl;
  return sendRequest('post', url, data);
};

export const obtenerAgendaVideollamada = (tipoTurno, fechaInicio, fechaFin) => {
  const url = tipoTurnos[tipoTurno].obtenerAgendaVideollamadaUrl(
    fechaInicio,
    fechaFin,
  );
  return sendRequest('get', url);
};

export const cancelarTramite = tramiteId => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'delete',
    url: CANCELAR_TRAMITE_URL(tramiteId),
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const cancelarTramiteConsulta = tramiteId => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'delete',
    url: CANCELAR_TRAMITE_CONSULTA_URL(tramiteId),
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

// ---------------------------------------------------------------------------
// Perfil ciudadano
// ---------------------------------------------------------------------------
export const crearCiudadano = data => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: CIUDADANOS_CREATE_URL,
    data,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const obtenerInfoDnic = ci => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: CIUDADANO_OBTENER_INFO_DNIC_URL(ci),
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const obtenerCedulaDnic = (ci, nroSerie) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: CIUDADANO_OBTENER_INFO_DNIC_CON_FOTO(ci, nroSerie),
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const actualizarEmailCiudadano = (id, data) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: CIUDADANO_UPDATE_EMAIL_URL(id),
    headers,
    data,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const actualizarTelefonoCiudadano = (id, data) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: CIUDADANO_UPDATE_PHONE_URL(id),
    headers,
    data,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const actualizarDatosPersonalesCiudadano = (id, data) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'put',
    url: CIUDADANO_DETAIL_URL(id),
    headers,
    data,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const eliminarCiudadano = id => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'delete',
    url: CIUDADANO_DETAIL_URL(id),
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const reenviarEmailDesbloqueo = id => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: REQUEST_RESET_PASSWORD_URL(id),
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const reenviarEmailActivacion = id => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: RESEND_ACTIVATION_EMAIL_URL(id),
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const verificarCiudadano = id => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'post',
    url: CIUDADANO_FACE_VERIFIED_URL(id),
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const obtenerAccionesCiudadano = id => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: CIUDADANO_ACCIONES_URL(id),
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

// ---------------------------------------------------------------------------
// Gestión de agentes
// ---------------------------------------------------------------------------
export const obtenerAgentesOrganismo = (pagina, params) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: OBTENER_AGENTES_ORGANISMO(pagina),
    headers,
    params,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const editarPermisosAgente = (agente, data) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'POST',
    url: EDITAR_PERMISOS_AGENTE(agente),
    headers,
    data,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const obtenerOrganismos = url => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: url || `${ORGANISMOS_BASE_URL}/`,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

// ---------------------------------------------------------------------------
// Búsqueda de ciudadanos
// ---------------------------------------------------------------------------
export const buscarCiudadanos = (filter = '', page = 1) => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: `${CIUDADANOS_SEARCH_LIST_URL}${filter}&page=${page}`,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

// ---------------------------------------------------------------------------
// Estadísticas
// ---------------------------------------------------------------------------
export const obtenerEstadisticasDeNivelesCiudadanos = () => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: ESTADISTICAS_DE_NIVELES_CIUDADANOS_URL,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const obtenerEstadisticasDeCiudadanosLogueados = () => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: ESTADISTICAS_DE_CIUDADANOS_LOGUEADOS_URL,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const obtenerEstadisticasDeCiudadanosConEmailDuplicado = () => {
  const headers = {
    Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
  };
  return axiosAPI({
    method: 'get',
    url: ESTADISTICAS_DE_CIUDADANOS_CON_EMAIL_DUPLICADO_URL,
    headers,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
};

export const fetchVideollamadaSoporte = uuid =>
  axiosAPI({
    method: 'get',
    url: `${CONSULTA_VIDEOLLAMADA_BASE_URL}${uuid}`,
  })
    .then(resp => resp)
    .catch(err => {
      throw err.response;
    });
