// ---------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------
export const API_REQUEST = 'API_REQUEST';

// ---------------------------------------------------------------------------
// Agentes
// ---------------------------------------------------------------------------
export const AGENTES_BASE_URL = '/rest/agentes';
export const FETCH_AGENTE_URL = id => `${AGENTES_BASE_URL}/${id}/`;
export const OBTENER_AGENTES_ORGANISMO = pagina =>
  pagina ? `${AGENTES_BASE_URL}/?page=${pagina}` : `${AGENTES_BASE_URL}/`;
export const EDITAR_PERMISOS_AGENTE = id =>
  `${AGENTES_BASE_URL}/${id}/editar_permisos_agente/`;

// ---------------------------------------------------------------------------
// Organismos
// ---------------------------------------------------------------------------
export const ORGANISMOS_BASE_URL = '/rest/organismos';

// ---------------------------------------------------------------------------
// Ciudadanos
// ---------------------------------------------------------------------------
export const CIUDADANOS_BASE_URL = '/rest/ciudadanos';
export const CIUDADANOS_AGENTES_BASE_URL = '/rest/agentes/ciudadanos';
export const CIUDADANOS_SEARCH_LIST_URL = `${CIUDADANOS_AGENTES_BASE_URL}/?search=`;
export const CIUDADANO_DETAIL_URL = id =>
  `${CIUDADANOS_AGENTES_BASE_URL}/${id}/`;
export const CIUDADANOS_CREATE_URL = `${CIUDADANOS_AGENTES_BASE_URL}/`;
export const CIUDADANO_FACE_VERIFIED_URL = id =>
  `${CIUDADANOS_AGENTES_BASE_URL}/${id}/cambiar_nivel_de_confianza_presencial/`;
export const CIUDADANO_UPDATE_EMAIL_URL = id =>
  `${CIUDADANOS_AGENTES_BASE_URL}/${id}/update_email/`;
export const CIUDADANO_UPDATE_PHONE_URL = id =>
  `${CIUDADANOS_AGENTES_BASE_URL}/${id}/update_numero_telefono/`;
export const RESEND_ACTIVATION_EMAIL_URL = id =>
  `${CIUDADANOS_AGENTES_BASE_URL}/${id}/resend_activation/`;
export const REQUEST_RESET_PASSWORD_URL = id =>
  `${CIUDADANOS_AGENTES_BASE_URL}/${id}/resend_reset_password/`;
export const CIUDADANO_OBTENER_INFO_DNIC_URL = ci =>
  `${CIUDADANOS_AGENTES_BASE_URL}/obtener_info_dnic/?ci=${ci}`;
export const CIUDADANO_OBTENER_INFO_DNIC_CON_FOTO = (ci, nroSerie) =>
  `${CIUDADANOS_AGENTES_BASE_URL}/obtener_info_dnic/?ci=${ci}&nro_serie=${nroSerie}&con_foto=True`;
export const CIUDADANO_ACCIONES_URL = id =>
  `${CIUDADANOS_BASE_URL}/${id}/obtener_acciones/`;

// ---------------------------------------------------------------------------
// JWT
// ---------------------------------------------------------------------------
export const LOGOUT_URL = '/auth/logout';
export const REFRESH_TOKEN_URL = '/jwt/refresh/';
export const OBTAIN_TOKEN_URL = '/jwt/obtain/';
export const REFRESH_INTERVAL = 55000; // Miliseconds

// ---------------------------------------------------------------------------
// Paises y documentos
// ---------------------------------------------------------------------------
export const FETCH_PAISES_URL = '/rest/paises/';

// ---------------------------------------------------------------------------
// Videollamada
// ---------------------------------------------------------------------------

// Validación
export const TRAMITE_VIDEOLLAMADA_BASE_URL = '/rest/tramite-videollamada/';
export const FETCH_VIDEOLLAMADA_URL = id =>
  `${TRAMITE_VIDEOLLAMADA_BASE_URL}${id}/`;
export const ESTADO = id => `${TRAMITE_VIDEOLLAMADA_BASE_URL}${id}/estado/`;
export const INFO_DNIC = 'rest/info-dnic/';
export const RECONOCIMIENTO_FACIAL = 'rest/reconocimiento-facial/';
export const FETCH_AGENTE_AGENDA_URL = (fechaInicio, fechaFin) =>
  `${TRAMITE_VIDEOLLAMADA_BASE_URL}?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`;
export const ACEPTAR_TRAMITE = pk =>
  `${TRAMITE_VIDEOLLAMADA_BASE_URL}${pk}/aceptar/`;
export const RECHAZAR_TRAMITE = pk =>
  `${TRAMITE_VIDEOLLAMADA_BASE_URL}${pk}/rechazar/`;
export const SIGUIENTE_TRAMITE_URL = `${TRAMITE_VIDEOLLAMADA_BASE_URL}siguiente-tramite/`;
export const DESVINCULAR_AGENTE_TRAMITE_URL = uuid =>
  `${TRAMITE_VIDEOLLAMADA_BASE_URL}${uuid}/desvincular-agente-tramite/`;
export const CANCELAR_TRAMITE_URL = pk =>
  `${TRAMITE_VIDEOLLAMADA_BASE_URL}${pk}/cancelar-tramite/`;

// Consulta
export const CONSULTA_VIDEOLLAMADA_BASE_URL = '/rest/videollamada-soporte/';
export const FETCH_AGENTE_AGENDA_CONSULTA_URL = (fechaInicio, fechaFin) =>
  `${CONSULTA_VIDEOLLAMADA_BASE_URL}?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`;
export const FINALIZAR_TRAMITE_CONSULTA = pk =>
  `${CONSULTA_VIDEOLLAMADA_BASE_URL}${pk}/finalizar/`;
export const SIGUIENTE_TRAMITE_CONSULTA_URL = `${CONSULTA_VIDEOLLAMADA_BASE_URL}siguiente-tramite/`;
export const DESVINCULAR_AGENTE_TRAMITE_CONSULTA_URL = pk =>
  `${CONSULTA_VIDEOLLAMADA_BASE_URL}${pk}/desvincular-agente-tramite/`;
export const CANCELAR_TRAMITE_CONSULTA_URL = pk =>
  `${CONSULTA_VIDEOLLAMADA_BASE_URL}${pk}/cancelar-tramite/`;

// ---------------------------------------------------------------------------
// Configuración turno
// ---------------------------------------------------------------------------
// Videollamada
export const CONFIGURACION_TURNO_BASE_URL = '/rest/configuracion-turno/';
export const FETCH_CONFIGURACION_TURNO_LISTAR_URL = (fechaInicio, fechaFin) =>
  `${CONFIGURACION_TURNO_BASE_URL}?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`;
export const ELIMINAR_CONFIGURACIONES_URL = `${CONFIGURACION_TURNO_BASE_URL}borrar-por-lista/`;
// Consulta
export const CONFIGURACION_TURNO_CONSULTA_BASE_URL =
  '/rest/configuracion-turno-consulta/';
export const FETCH_CONFIGURACION_TURNO_CONSULTA_LISTAR_URL = (
  fechaInicio,
  fechaFin,
) =>
  `${CONFIGURACION_TURNO_CONSULTA_BASE_URL}?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`;
export const ELIMINAR_CONFIGURACIONES_CONSULTA_URL = `${CONFIGURACION_TURNO_CONSULTA_BASE_URL}borrar-por-lista/`;

// ---------------------------------------------------------------------------
// Estadísticas
// -
export const ESTADISTICAS_DE_NIVELES_CIUDADANOS_URL = 'niveles-ciudadanos';
export const ESTADISTICAS_DE_CIUDADANOS_LOGUEADOS_URL = 'ciudadanos-logueados';
export const ESTADISTICAS_DE_CIUDADANOS_CON_EMAIL_DUPLICADO_URL =
  'ciudadanos-con-email-duplicado';
