import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const ButtonFlat = ({
  text,
  handleOnClick,
  handleOnMouseEnter,
  handleOnMouseLeave,
  isSmall = false,
  className = '',
  disabled,
  shortLineHeight,
}) => {
  const getClass = () => {
    if (isSmall) return 'button-flat--small';
    if (shortLineHeight) return 'button-flat--short-line-height';
    return 'button-flat';
  };
  const getClassResult = getClass();
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={`${getClassResult} ${className}`}
      onClick={handleOnClick}
      disabled={disabled}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <span className="button-flat__text">{text}</span>
    </button>
  );
};

ButtonFlat.propTypes = {
  text: PropTypes.string,
  handleOnClick: PropTypes.func,
  handleOnMouseEnter: PropTypes.func,
  handleOnMouseLeave: PropTypes.func,
  isSmall: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  shortLineHeight: PropTypes.bool,
};

export default ButtonFlat;
